const { useSession } = require("providers/SessionProvider");
const { Outlet, Navigate } = require("react-router-dom");

const RoleGuard = ({ role }) => {
    const { user } = useSession();

    return (
        user.role === role
            ? <Outlet /> : <Navigate to="/number-detail" />
    );
}

export default RoleGuard;
